import {Injectable, OnInit} from '@angular/core';
import {Constant} from "../constant";
import { HttpClient } from '@angular/common/http';
import { UserProfile } from '../models/users.model';
import { AppQueries, Dispatcher, StoreInfoUser } from 'src/app/state';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService implements OnInit  {
  static user: UserProfile = new UserProfile();
  constructor(
    private http: HttpClient,
    private dispatcher: Dispatcher
  ) {
  }
  ngOnInit() {
  }
  static storeProfile(userProfile) {
    this.user = userProfile;
  }
  static getProfile() {
    return this.user;
  }
  clear() {
    this.dispatcher.fire(new StoreInfoUser(new UserProfile()));
  }
  getProfileAPI() {
    return new Promise<boolean>((resolve, reject) => {
      this.http.get(environment.urlBackEnd +  Constant.api_user_profile)
      .subscribe((res: any) => {
        if (res.data) {
          const profile = new UserProfile(res.data);
          profile.isCheckFullPrivateInfo = this.checkFullPrivateInformation(profile);
          this.dispatcher.fire(new StoreInfoUser(profile));
          resolve(res.data);
        } else {
          resolve(false);
        }
      }, (error) => {
        reject(error)
      })
    })
  }
  checkFullPrivateInformation(user) {
    if (!user.name || !user.birthDate || !user.address || !user.provinceId) {
      return false;
    }
    if (!user.idTypeId || !user.passport || !user.passportPlaceOfIssue || !user.passportDateOfIssue 
      || !user.passportFrontFaceUrl || !user.passportBackFaceUrl || !user.selfieUrl) {
      return false;
    }
    if (!user.bankAccountName || !user.bankNumber || !user.bankId) {
      return false;
    }
    return true;
  }
  checkAuthorUrl(route: any) {
    // let url = route._routerState.url;
    // let listChildren= [];
    // if (this.user.userType === Constant.INVESTOR) {
    //   listChildren = ROUTE_INVESTOR || [];
    // } 
    // return listChildren.some(children => children.path.includes(url) || url.includes(children.path) );
    return true;
  }
  
  firstUrl() {
    let route: any;
    return route  ? route.path : '';
  }
}

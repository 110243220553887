import { Injectable }           from '@angular/core';
import { Observable }           from 'rxjs';
import { CanDeactivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot }  from '@angular/router';

export interface CanComponentDeactivate {
  confirmChangeRouter: (url?) => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {
  previousState: any;
  exceptList: any = []
  canDeactivate(
    component: CanComponentDeactivate,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise<boolean>((resolve, reject) => {
      this.previousState = this.previousState || '';
      if (!nextState.url.includes('auth/login') && !nextState.url.includes('notice-limited-functionality')) {
        if (!component) {
          resolve(true);
        } else if (component.confirmChangeRouter) {
          const func: any = component.confirmChangeRouter(nextState.url)
          func.then((res) => {
            if (res) {
              this.previousState = nextState.url;
            }
            resolve(res);
          })
        } else {
          resolve(true);
        }
      } else {
        this.previousState = nextState.url;
        resolve(true);
      }
    })
  }
}
